<template>
 <CCard>
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New Symptoms</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
    
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       
      pagination></CDataTable>
  </CCardBody>
 </CCard>
</template>

<script>
const fields = [
  { key: "categoryId",  _style: "min-width:200px" },
  { key: "name", _style: "min-width:200px" },
  { key: "created_time", _style: "min-width:200px" }
];
export default {
  name: "Symptoms",
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getSymptoms() {
      fetch(process.env.VUE_APP_API_HOST + "/web/secure/symptoms")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    navToNewSymptoms(){
       this.$router.push({ name: "Symptoms", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getSymptoms();
  },
};
</script>